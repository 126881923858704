import { Fa6SolidMedal } from '@/assets/icons/comm/Fa6SolidMedal';
import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseTopNav from '@/components/base/baseTopNav';
import RebateRankingTopTitles from '@/pages/invite/rebateRanking/components/RebateRankingTopTitles';
import { getRankedListApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { useMount } from 'ahooks';
import { useTranslation } from 'react-i18next';
import ProjectSetting from '@/setting/projectSetting';

export default () => {
  const { t } = useTranslation();
  // 获取排名列表
  const {
    run: getRankedListReq,
    data: rankedList = [] as any,
    loading,
  } = useReq(getRankedListApi, {
    manual: true,
  });
  useMount(() => {
    getRankedListReq();
  });
  return (
    <div className="h-[100vh] flex flex-col">
      <BaseTopNav title={t('好友推荐项目|推荐好友&赢取奖励')} />
      <div className="px-4">
        {/*顶部文字*/}
        <RebateRankingTopTitles />
      </div>
      <div className="bg-backgroundAuxiliaryColor flex-1  rounded-tl-2xl rounded-tr-2xl text-foreground px-4 pt-8 text-sm mt-8">
        <BaseLoadingDisplay loading={loading} list={rankedList}>
          {(rankedList as any)?.map((item: any, index: number) => {
            return (
              <div className="flex justify-between mb-6" key={item}>
                <div className="flex items-center">
                  <div className="w-[30px]">
                    {index === 0 && (
                      <Fa6SolidMedal className="text-[#edc349]" />
                    )}
                    {index === 1 && (
                      <Fa6SolidMedal className="text-[#9e9e9e]" />
                    )}
                    {index === 2 && (
                      <Fa6SolidMedal className="text-[#c4972d]" />
                    )}
                    {index > 2 && index + 1}
                  </div>

                  <span className="ml-4">ID {item?.nickname}</span>
                </div>
                <span>{RenderUtil.FormatAmount(item?.totalAmount)} {ProjectSetting.APP_DEFAULT_CURRENCY}</span>
              </div>
            );
          })}
        </BaseLoadingDisplay>
      </div>
    </div>
  );
};
