import BaseModal from '@/components/base/baseModal';
import { useDisclosure } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div className="mt-8">
        <h3 className="text-2xl text-backContrastColor">{t('排名列表')}</h3>
        <p className="text-backContrastColor py-4">
          {t('过去24小时的排名数据.')}
        </p>
        {/*<div*/}
        {/*  onClick={onOpen}*/}
        {/*  className="flex items-center text-sm*/}
        {/* text-primary"*/}
        {/*>*/}
        {/*  {t('如何获得更多返佣')}*/}
        {/*  <IconParkOutlineRight />*/}
        {/*</div>*/}
      </div>
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        placement="center"
        title={t('具体规则')}
      >
        <div className="w-[300px]">{t('具体规则内容')}</div>
      </BaseModal>
    </>
  );
};
